import React, { Component } from 'react'

export default class Experience extends Component {
	render() {
		return (
			<section className="colorlib-experience" data-section="experience">
				<div className="colorlib-narrow-content">
					<div className="row">
						<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
							<span className="heading-meta">Experience</span>
							<h2 className="colorlib-heading animate-box">My Experience</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
						 <div className="timeline-centered">
							 <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
								<div className="timeline-entry-inner">

								   <div className="timeline-icon color-1">
									  <i className="icon-pen2"></i>
								   </div>

								   <div className="timeline-label">
									  <h2><a>Software Engineer</a> <span>July 2018 - Present</span></h2>
									  <h2><i className="icon-briefcase3"/><a style={{fontSize: 18}}> Clarabridge Inc</a> <span><i className="icon-location4"/> Reston, VA</span></h2>
									  <p>Building dashboarding & data visualization application that helps world's leading brands transform their customer feedbacks into actionable insights used to make business decisions.</p>
								   </div>
								</div>
							 </article>

							 <article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
								<div className="timeline-entry-inner">
									<div className="timeline-icon color-2">
										<i className="icon-pen2"></i>
									</div>
									<div className="timeline-label">
										<h2><a>Undergraduate Student</a> <span>August 2014 - May 2018</span></h2>
										<h2><i className="icon-study"/> <a style={{fontSize: 18}}>University of Virginia</a> <span><i className="icon-location4"/> Charlottesville, VA</span></h2>
										<p>Bachelor's Degree with a Double Major in Computer Science and Cognitive Science</p>
									</div>
								</div>
							 </article>

							 {/* <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
								<div className="timeline-entry-inner">
									<div className="timeline-icon color-3">
										<i className="icon-pen2"></i>
									</div>
									<div className="timeline-label">
										<h2><a>Digital Operations Intern </a> <span>June 2016 - August 2016</span></h2>
										<h2><i className="icon-briefcase3"/> <a style={{fontSize: 18}}>Youku Inc</a> <span><i className="icon-location4"/> Beijing, China</span></h2>
										<p></p>
									</div>
								</div>
							 </article> */}

							 {/* <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
								<div className="timeline-entry-inner">
									<div className="timeline-icon color-4">
										<i className="icon-pen2"></i>
									</div>
									<div className="timeline-label">
										<h2><a>Data Intern</a> <span>May 2016 - June 2016</span></h2>
										<h2><i className="icon-briefcase3"/> <a style={{fontSize: 18}}>JD.com Inc</a> <span><i className="icon-location4"/> Beijing, China</span></h2>
									 	<p></p>
									</div>
								</div>
							 </article> */}

							 {/* <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
								<div className="timeline-entry-inner">
								   <div className="timeline-icon color-5">
									  <i className="icon-pen2"></i>
								   </div>
								   <div className="timeline-label">
										<h2><a>Asset Management Intern</a> <span>June 2015 - August 2015</span></h2>
									  	<h2><i className="icon-briefcase3"/> <a style={{fontSize: 18}}>Shenwan Hongyuan Securities Co. Ltd</a> <span><i className="icon-location4"/> Beijing, China</span></h2>
									 	<p></p>
								   </div>
								</div>
							 </article> */}

							 <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
								<div className="timeline-entry-inner">
								   <div className="timeline-icon color-none">
								   </div>
								</div>
							 </article>
						  </div>
					   </div>
				   </div>
				</div>
			</section>
		)
	}
}

