import React, { Component } from 'react'

export default class Fitness extends Component {
	render() {
		return (
			<section className="colorlib-fitness" data-section="fitness">
				<div className="colorlib-narrow-content">
					<div className="row">
						<div className="col-md-12">
							<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
								<div className="col-md-12">
									<div className="about-desc">
										<span className="heading-meta">Fitness</span>
										<h2 className="colorlib-heading">My Fitness Journey</h2>
										<p>Will post when I am finally determined to compete in Men's Physique someday...LOL</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row" style={{ paddingBottom: "20em"}}/>

			</section>
		)
	}
}

