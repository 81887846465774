import React, { Component } from 'react'
import moment from 'moment';

export default class Stats extends Component {
	render() {
		let age = moment().diff(moment('19960105', 'YYYYMMDD'), 'years')
		return (
			<section className="colorlib-stats" data-section="stats">
				<div className="row">
					<div className="colorlib-narrow-content">
						<div className="col-md-8 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
							<span className="heading-meta">Stats</span>
							<h2 className="colorlib-heading animate-box">Some Interesting Numbers About Me</h2>
						</div>
					</div>
				</div>
				<div id="colorlib-counter" className="colorlib-counters" style={{backgroundImage: 'url(images/stats_cover.jpg)'}} data-stellar-background-ratio="0.5">
					<div className="overlay"></div>
					<div className="colorlib-narrow-content">
						<div className="row">
						</div>
						<div className="row">
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to={age} data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Years Old</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="1" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Dog</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="11" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Countries Visited</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="13" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">lbs down in one month</span>
							</div>
						</div>

						<div className="row">
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="27" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Mandarin oranges in a day</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="3" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Beers to get drunk</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="5" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Bikes Lost</span>
							</div>
							<div className="col-md-3 text-center animate-box">
								<span className="colorlib-counter js-counter" data-from="0" data-to="19" data-speed="5000" data-refresh-interval="50"></span>
								<span className="colorlib-counter-label">Game consoles owned</span>
							</div>
						</div>

					</div>
				</div>
			</section>
		)
	}
}

