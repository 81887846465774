import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import Modal from 'react-modal'

import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Stats from './components/Stats';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Fitness from './components/Fitness';
import Projectcard from './components/Projectcard';


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
  		borderRadius: 5
	}
};

Modal.setAppElement('#root')


class App extends Component {
	constructor() {
		super()
 
		this.state = { 
			modalIsOpen: false,  
			projectKey: ''
		}
 
		this.openModal = this.openModal.bind(this)
		this.afterOpenModal = this.afterOpenModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}

	openModal(projectKey) {
		this.setState({modalIsOpen: true, projectKey: projectKey})
	}
 
	afterOpenModal() {
		// references are now sync'd and can be accessed.
		// this.subtitle.style.color = '#f00'
	}
 
	closeModal() {
		this.setState({modalIsOpen: false})
	}

	render() {
		return (
			<div id="colorlib-page">
				<div id="container-wrap">
					<Navbar/>
					<div id="colorlib-main">
						<Home/>
						<About/>
						<Stats/>
						<Experience/>
						<Projects openModal={this.openModal} />
						<Fitness/>
						<div className="modal-shadow">
							<Modal
								closeTimeoutMS={500}
								isOpen={this.state.modalIsOpen}
								onAfterOpen={this.afterOpenModal}
								onRequestClose={this.closeModal}
								style={customStyles}
								contentLabel="Example Modal">

								<Projectcard projectKey={this.state.projectKey}/>

							</Modal>
						</div>
					</div>
				</div>
			</div>
		)
	}		
}

export default App;