import React, { Component } from 'react'

export default class Navbar extends Component {
	render() {
		let currentYear = new Date().getFullYear()

		return (
			<div>
			<a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i></i></a>
				<aside id="colorlib-aside" role="complementary" className="border js-fullheight">
					<div className="text-center">
						<div className="author-img" style={{ backgroundImage: 'url(images/profile.jpg)'}}></div>
						<h1 id="colorlib-logo"><a href="index.html">Yixuan Qian</a></h1>
						<span className="email"><i className="icon-mail5"></i> qianyixuaneric@gmail.com</span>
						<span className="link-icons">
							<ul>
								<li><a href="https://github.com/Machoper/" target="_blank"><i className="icon-github"></i></a></li>
								<li><a href="https://www.linkedin.com/in/yixuan-qian-675684b3/" target="_blank"><i className="icon-linkedin2"></i></a></li>
								<li><a href="https://www.instagram.com/chop_qyx/" target="_blank"><i className="icon-instagram"></i></a></li>
								<li><a href="https://www.facebook.com/yixuan.qian.7/" target="_blank"><i className="icon-facebook2"></i></a></li>
							</ul>
						</span>
						<hr/>
					</div>
					<nav id="colorlib-main-menu" role="navigation" className="navbar">
						<div id="navbar" className="collapse">
							<ul>
								<li className="active"><a href="#" data-nav-section="home">Home</a></li>
								<li><a href="#" data-nav-section="about">About</a></li>
								<li><a href="#" data-nav-section="stats">Stats</a></li>
								<li><a href="#" data-nav-section="experience">Experience</a></li>
								<li><a href="#" data-nav-section="projects">Projects</a></li>
								<li><a href="#" data-nav-section="fitness">Fitness</a></li>
							</ul>
						</div>
						{/*<div className="link-icons">
							<ul>
								<li><a href="https://github.com/Machoper/" target="_blank"><i className="icon-github"></i></a></li>
								<li><a href="https://www.linkedin.com/in/yixuan-qian-675684b3/" target="_blank"><i className="icon-linkedin2"></i></a></li>
								<li><a href="https://www.instagram.com/chop_qyx/" target="_blank"><i className="icon-instagram"></i></a></li>
								<li><a href="https://www.facebook.com/yixuan.qian.7/" target="_blank"><i className="icon-facebook2"></i></a></li>
							</ul>
						</div>*/}
					</nav>

					<div className="colorlib-footer">
						<hr/>
						<p>
							<small>&copy;{currentYear} Yixuan Qian <br/>· Powered by <a href="https://colorlib.com" target="_blank">Colorlib</a>, <a href="https://reactjs.org/" target="_blank">React</a>
							</small>
						</p> 
					</div>
				</aside>
			</div>	
		)
	}
}