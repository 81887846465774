import React, { Component } from 'react'

export default class About extends Component {
	render() {
		return (
			<section className="colorlib-about" data-section="about">
				<div className="colorlib-narrow-content">
					<div className="row">
						<div className="col-md-12">
							<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
								<div className="col-md-12">
									<div className="about-desc">
										<span className="heading-meta">About</span>
										<h2 className="colorlib-heading">A Little Background</h2>
										<p>Graduated from University of Virginia with a double major in Computer Science and Cognitive Science, I have developed extensive knowledge in programming, algorithms, data structures, and neuroscience from relevant coursework. 
										After graduation, I have been working as a full stack software engineer where I spend most of my time developing cool features and fixing bugs.<br/>
										I'm also crazy about bodybuilding and plan to enter in a men's physique competition someday.<br/>
										I have a small white dog named Nomi. Super cute and friendly. See if you can find her on this page <i className="icon-eye3"/> <i className="icon-eye3"/><br/>
										In my spare time, I enjoy traveling, cooking, and playing video games.</p>
										{/*<h2 className="colorlib-heading">Some of my expertise include:</h2>*/}
									</div>
								</div>
							</div>

							<div className="animate-box" data-animate-effect="fadeInRight" style={{paddingTop: 30}}>
								<h2 className="colorlib-heading">Ultimate Career Objective</h2>
								<p>The generation of those who had computers and played video games from the early childhood has not only grown up but also taken key roles in the modern IT industry, either as customers or developers. 
								From a very young age, the motivation system of our generation has been built around passion and awards. 
								We have a good idea of what levels, quests, awards, and achievements are, but at the same time, we may not always understand how to make an application more accessible to users or how to get the most of online services. 
								Not just games alone, there are so many other cognitive processes natural to human beings that have yet to be largely promoted but could radically transform the way we interact with systems and each other in our digital lives. 
								Given my background in programming and cognitive neuroscience, I would like to work on bringing cognitive computing into business processes, specifically software development, to drive user engagement, to increase productivity, and also to facilitate the introduction of innovations.</p>
							</div>

							<h2 className="colorlib-heading animate-box" data-animate-effect="fadeInLeft" style={{paddingTop: 30}}>My current skill set</h2>
							<div className="row">
								<div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
									<div className="services color-1">
										<span className="icon2"><i className="icon-device-desktop"></i></span>
										<h3>Web Development</h3>
										<p>HTML, CSS, Angular, React, Django, Flask, Node.js, Express.js, Spring</p>
									</div>
								</div>
								<div className="col-md-3 animate-box" data-animate-effect="fadeInRight">
									<div className="services color-2">
										<span className="icon2"><i className="icon-code"></i></span>
										<h3>Programming Languages</h3>
										<p>JavaScript/TypeScript, Python, Java, C++</p>
									</div>
								</div>
								<div className="col-md-3 animate-box" data-animate-effect="fadeInTop">
									<div className="services color-3">
										<span className="icon2"><i className="icon-data"></i></span>
										<h3>Database</h3>
										<p>MongoDB, MySQL, Postgres, LDAP, Firebase</p>
									</div>
								</div>
								<div className="col-md-3 animate-box" data-animate-effect="fadeInBottom">
									<div className="services color-4">
										<span className="icon2"><i className="icon-settings"></i></span>
										<h3>System/Tools</h3>
										<p>Linux, Git, AWS, Docker, Jenkins, Kibana, Postman, MATLAB</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3 animate-box" data-animate-effect="fadeInBottom">
									<div className="services color-4">
										<span className="icon2"><i className="icon-phone3"></i></span>
										<h3>Mobile Development</h3>
										<p>Android Studio, XML, Xcode, Swift</p>
									</div>
								</div>
								<div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
									<div className="services color-5">
										<span className="icon2"><i className="icon-phone3"></i></span>
										<h3>Game Development</h3>
										<p>Unity, C#, Blender</p>
									</div>
								</div>
							</div>
							{/*<div className="row">
								<div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
									<div className="hire">
										<h2>I am happy to know you <br/>that 300+ projects done sucessfully!</h2>
										<a href="#" className="btn-hire">Hire me</a>
									</div>
								</div>
							</div>*/}
						</div>
					</div>
				</div>
			</section>
		)
	}
}

