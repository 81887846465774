import React, { Component } from 'react'

export default class Reportcard extends Component {
	render() {
		let content = {
			scrumster: {
				header: 'Scrumster',
				title: 'A interactive tool for Scrum ceremonies',
				text: <div>
						<p>Scrum members can easily join together in a virtual room to point tickets/tasks or participate in a retrospective session.</p>
						</div>,
				demo: 'https://www.scrumster.io',
				link: 'https://github.com/Machoper/scrumster'
			},
			byteMe: {
				header: 'Byte-Me',
				title: 'Online marketplace for buying and selling computers',
				text: <div>
						<p>This is a four-tier web application including the HTML front end, the experience service APIs, the Entity/Model APIs, and the database. Each layer will run in its own container and communicate via the network with the other layers. Users can create accounts, login and create new listings in the marketplace under authentication support.  </p>
						<p>Searching is implemented through Kafka and Elastic Search. Newly created listings are added to a Kafka queue. The search indexer takes new listings out of Kafka and indexes them into ES, which is then queried by a search result page (SRP).  </p>
						<p>We also build a recommendation system that generates accurate recommendations based on a recurring map/reduce job on Apache Spark. The Spark job takes a web site access log as input and as output produce data that will be used by the recommendation system.</p>
						</div>,
				link: 'https://github.com/Machoper/Byte-Me'
			},
			beSafe: {
				header: 'Be Safe',
				title: 'A personal and public safety Android application',
				text: <div>
						<p>This app provides users the ability to contact their family members and friends quickly and efficiently in emergent situations.  </p>
						<p>The app is particularly useful for locating lost friends on nights out and also allows users to send their circle of friends an instant call for help at the shake of the device. The GPS tracker will mark your location while sending a message to the primary emergency contact, ensuring you’ll always be able to find one another if necessary.</p>
						</div>,
				link: 'https://github.com/Machoper/BeSafe'
			},
			rainbowBall: {
				header: 'Become a Rainbow Ball',
				title: 'A 2D shooting game',
				text: <div>
						<p>This is a 2D shooting game coded in Java. The map is a 8x2 grid and the player must defeat all enemies in your section (a "left" and "right" room) to unlock the next section. Throughout the game, the player will gain various colored balls to act as power-ups or weapons. Colors will have different damage levels or special effects on different enemies. The player will slowly pick up more colors of the rainbow after traversing the grid and killing bosses. The ultimate goal is to defeat the final boss and go into the white light to win.  </p>
						</div>,
				link: 'https://github.com/Machoper/Rainbow-Ball'
			},
			neuralNetwork: {
				header: 'Character Recognition',
				title: 'A synaptogenesis model applied to Chinese characters learning',
				text: <div>
						<p>Learning is closely related to brain plasticity and the formation of neural synapses. Human beings are born with the potential to learn languages, but it is a hidden ability that needs to be activated and acquired before we can put to good use. Children growing up in presence of only one language are able to master the language with great ease because the constant stimulation in the environment has triggered an explosion of synapse formation during the early brain development of children, known as exuberant synaptogenesis. As the rate of synaptogenesis decreases throughout the lifespan, an adult will find it hard to learn a second language.  </p>
						<p>Chinese is deemed one of the most difficult languages for western people to learn as second language and is known for its complicated writing system. Due to its “flow” nature, recognizing such characters often requires domain-specific knowledge. This study presents a two-layer synaptogenesis model to simulate Chinese character learning and recognition. The first layer takes the character set at input while the second layer takes the output of the first layer as input and uses a supervised modification rule for decoding purpose. The model was successful in compressing pixelated information and identifying input patterns from specific characters to some extent. Further expansion and research on this topic may help language learning and improve accuracy of Chinese character generating in machine.  </p>
						</div>,
				link: 'https://github.com/Machoper/Neural_Network'
			},
			electech: {
				header: 'ElecTech',
				title: 'A voting system',
				text: <div>
						<p>System can check in a voter that is supposed to vote at this polling place and refuses a voter that should not vote here.</p>
						<p>System allows a voter to successfully record their vote on various election items.</p>
						<p>System creates a physical, traceable way to perform a manual recount.</p>
						<p>System provides an easy-to-use, functional API for getting information about vote counts. Documentation for the API is clear and well-written.</p>	
						</div>,
				link: 'https://github.com/Machoper/ElecTech'
			},
			racing: {
				header: 'F1/10 Autonomous Racing',
				title: 'A self-driving 1:10 F1 racing car',
				text: <div>
						<p>Robot Operating System Perception pipeline using LIDAR, Cameras, and IMU.</p>
						<p>Localization and Planning using Scan matching, kalman filter, particle filter (AMCL), vanishing point.</p>
						<p>Scene understanding, obstacle detection, lane detection, collision avoidance, optimal racing lines and racing strategies</p>
						</div>,
				link: 'https://github.com/Machoper/Autonomous_Racing'
			}

		}

		let target = content[this.props.projectKey]
		target = target || {header: '', title: '', text: ''}

		return (
			<div className="panel panel-info" style={{width: '50rem'}}>
				<div className="panel-heading">
					<span style={{fontSize: 20}}><strong>{target.header}</strong></span>
				</div>
				<div className="panel-body" style={{marginTop: 0}}>
					<p><h5><i>{target.title}</i></h5></p>
					<p className="panel-text" style={{maxHeight: "40rem", overflowY: "scroll"}}>{target.text}</p>
					{target.demo && <a href={target.demo} target="_blank" className="btn btn-primary" style={{float: 'left'}}>Demo</a>}
					{target.link && <a href={target.link} target="_blank" className="btn btn-primary" style={{float: 'right'}}>Link to Github</a>}
				</div>
			</div>
		)
	}
}