import React, { Component } from 'react'

export default class Projects extends Component {
	openProject(projectId) {
		return () => this.props.openModal(projectId)
	}

	render() {
		let bgImages = {
			scrumster: {backgroundImage: 'url(images/scrumster.png'},
			byteMe: {backgroundImage: 'url(images/byteMe.png)'},
			beSafe: {backgroundImage: 'url(images/beSafe.png)'},
			rainbowBall: {backgroundImage: 'url(images/rainbowBall.png)'},
			neuralNetwork: {backgroundImage: 'url(images/neuralNetwork.png)'},
			electech: {backgroundImage: 'url(images/voting_system.jpg)'},
			racing: {backgroundImage: 'url(images/f1_10_autonomous.jpg)'},
		}

		return (
			<section className="colorlib-projects" data-section="projects">
				<div className="colorlib-narrow-content">
					<div className="row">
						<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
							<span className="heading-meta">Projects</span>
							<h2 className="colorlib-heading animate-box">My Projects</h2>
						</div>
					</div>
					{/*<div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
						<div className="col-md-12">
							<p className="work-menu"><span><a href="#" className="active">Graphic Design</a></span> <span><a href="#">Web Design</a></span> <span><a href="#">Software</a></span> <span><a href="#">Apps</a></span></p>
						</div>
					</div>*/}
					<div className="row">
						<div className="col-md-6 animate-box" data-animate-effect="fadeInLeft" onClick={this.openProject('scrumster')}>
							<div className="project" style={bgImages.scrumster}>
								<div className="desc">
									<div className="con">
										<h3><a>Scrumster</a></h3>
										<span>Web Application</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>
*/}									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInLeft" onClick={this.openProject('byteMe')}>
							<div className="project" style={bgImages.byteMe}>
								<div className="desc">
									<div className="con">
										<h3><a>Byte-Me</a></h3>
										<span>Web Application</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>
*/}									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInRight" onClick={this.openProject('beSafe')}>
							<div className="project" style={bgImages.beSafe}>
								<div className="desc">
									<div className="con">
										<h3><a>Be Safe</a></h3>
										<span>Mobile Application</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>
*/}									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInTop" onClick={this.openProject('rainbowBall')}>
							<div className="project" style={bgImages.rainbowBall}>
								<div className="desc">
									<div className="con">
										<h3><a>Become a Rainbow Ball</a></h3>
										<span>Computer Game</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInLeft" onClick={this.openProject('electech')}>
							<div className="project" style={bgImages.electech}>
								<div className="desc">
									<div className="con">
										<h3><a>Electech</a></h3>
										<span>Web Application</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInRight" onClick={this.openProject('racing')}>
							<div className="project" style={bgImages.racing}>
								<div className="desc">
									<div className="con">
										<h3><a>F1/10 Autonomous Racing</a></h3>
										<span>Artificial Intelligence</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>
*/}									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 animate-box" data-animate-effect="fadeInBottom" onClick={this.openProject('neuralNetwork')}>
							<div className="project" style={bgImages.neuralNetwork}>
								<div className="desc">
									<div className="con">
										<h3><a>Character Recognition</a></h3>
										<span>Neural Network Simulation</span>
										{/*<p className="icon">
											<span><a href="#"><i className="icon-share3"></i></a></span>
											<span><a href="#"><i className="icon-eye"></i> 100</a></span>
											<span><a href="#"><i className="icon-heart"></i> 49</a></span>
										</p>
*/}									</div>
								</div>
							</div>
						</div>
					</div>
					{/*<div className="row">
						<div className="col-md-12 animate-box">
							<p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload"></i></a></p>
						</div>
					</div>*/}
				</div>
			</section>
		)
	}
}
