import React, { Component } from 'react'

export default class Home extends Component {
	render() {
		return (
			<section id="colorlib-hero" className="js-fullheight" data-section="home">
				<div className="flexslider js-fullheight">
					<ul className="slides">
						<li style={{backgroundImage: 'url(images/img_bg_1.jpg)'}}>
							<div className="overlay"></div>
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
										<div className="slider-text-inner js-fullheight">
											<div className="desc">
												<h1>Hi! <br/>I'm Yixuan</h1>
												<h2>A nice human being with a white fluffy dog <i className="icon-heart" style={{color: "#f00"}}></i></h2>
												{/*<p><a className="btn btn-primary btn-learn">Download CV <i className="icon-download4"></i></a></p>*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li style={{backgroundImage: 'url(images/img_bg_2.jpg)'}}>
							<div className="overlay"></div>
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
										<div className="slider-text-inner">
											<div className="desc">
												<h1>I'm <br/>a Software Engineer</h1>
												<h2>in full stack web development with a strong interest in game design</h2>
												{/* <p>
													<a href="https://drive.google.com/file/d/1TWjW5YhRv4fv8wpex5_WGTknqzub_j-9/view?usp=sharing" target="_blank" className="btn btn-primary btn-learn">View CV <i className="icon-briefcase3"></i></a>
												</p> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li style={{backgroundImage: 'url(images/img_bg_3.jpg)'}}>
							<div className="overlay"></div>
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
										<div className="slider-text-inner">
											<div className="desc home-fitness">
												<h1>I'm also<br/>a Gym Rat</h1>
												<h2>who works out 6 times a week</h2>
												{/*<p><a className="btn btn-primary btn-learn">View Photos <i className="icon-briefcase3"></i></a></p>*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</section>
		)
	}
}

